<template>
  <div>
    <v-layout align-center>
      <v-autocomplete
        :search-input.sync="search"
        :value="selectedPathogens"
        :items="pathogens"
        clearable
        :loading="isLoading"
        item-text="name"
        :label="label"
        return-object
        multiple
        chips
        @change="emitInput"
        :hide-no-data="searched"
      >
      </v-autocomplete>
      <table-action-button
        @click="dialog = true"
        large
        icon="add_circle"
        text="Novo Patógeno"
      />
    </v-layout>
    <v-dialog v-model="dialog" v-if="dialog" :width="600">
      <form-pathogen @create="createdForm"></form-pathogen>
    </v-dialog>
  </div>
</template>

<script>
import { findByName } from "@/services/pathogens-service";
export default {
  components: {
    "form-pathogen": () => import("@/components/Pathogens/Form"),
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    dialog: false,
    pathogens: [],
    search: "",
    selectedPathogens: [],
  }),
  created() {
    this.loadValueToItems(this.value);
  },
  watch: {
    search(value) {
      if (value && value.length > 0) {
        this.filter(value);
      }
    },
    value(value) {
      this.loadValueToItems(value);
    },
  },
  computed: {
    searched() {
      return this.search && this.search.length === 0;
    },
    isLoading() {
      return this.$store.getters["loading/isLoading"];
    },
  },
  methods: {
    loadValueToItems(value) {
      if (value && value.length > 0) {
        this.pathogens = value;
        this.selectedPathogens = value;
      }
    },
    createdForm(createdPathogen) {
      this.selectedPathogens.push(createdPathogen);
      this.$emit("input", this.selectedPathogens);
      this.dialog = false;
    },
    async filter(value) {
      try {
        let response = await findByName(value);
        this.pathogens = this.pathogens.concat(response.data);
      } catch (error) {
        this.$toasted.global.defaultError();
      }
    },
    emitInput(value) {
      this.search = "";
      this.$emit("input", value);
    },
  },
};
</script>

<style></style>
